body {
  background: rgb(239, 241, 245);
  background: linear-gradient(180deg, rgba(239, 241, 245, 1) 0%, rgba(148, 219, 233, 1) 100%);
}

.nav-container {
  background: rgb(239, 241, 245);
  background: linear-gradient(180deg, rgba(239, 241, 245, 1) 0%, rgba(148, 219, 233, 1) 100%);

}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}


.card-group {
  margin: 150px 0px 0px 0px;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.card {
  position: relative;
  height: 270px;
  width: 450px;
  border-radius: 25px;
  background: linear-gradient(to left, #0D1838, #0E305D);
  backdrop-filter: blur(30px);
  /* This will produce the blur */
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.chip img,
.number,
.name,
.to,
.ring {
  position: absolute;
  /* All items inside card should have absolute position */
}


.chip img {
  top: 120px;
  left: 40px;
  width: 50px;
  height: auto; 
}

.number,
.name,
.to {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  letter-spacing: 2px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

.number {
  left: 40px;
  bottom: 65px;
  font-family: "Nunito", sans-serif;
  font-size: 1.5rem;
}

.name {
  font-size: 1rem;
  left: 40px;
  bottom: 35px;
}

.to {
  font-size: 1rem;
  bottom: 35px;
  right: 40px;
}

.ring {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: transparent;
  border: 50px solid rgba(249, 249, 250, 0.2);
  bottom: -250px;
  right: -250px;
  box-sizing: border-box;
}

.ring::after {
  content: "";
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: transparent;
  border: 30px solid rgba(255, 255, 255, 0.1);
  bottom: -80px;
  right: -110px;
  box-sizing: border-box;
}


